<template>
  <div>
    <div :class="{ invalid: $v.form.batchName.$error }">
      <v-text-field
        v-model="$v.form.batchName.$model"
        persistent-hint
        label="Batch Name"
        class="my-0 pt-0"
      ></v-text-field>

      <div class="invalid-feedback" v-if="$v.form.batchName.$error">
        <span v-if="$v.form.batchName.$error"
          >create a name for this batch</span
        >
      </div>
    </div>
    <div>
      <div>
        <span class="text-color"> <b>Service Period</b> </span> <br />
        <span>Select the Service period for this batch </span>
      </div>
      <date-range-picker
        ref="picker" 
        open="center" 
        v-model="form.dates"
        :ranges="false"
        prepend-icon="mdi-bell"
        ><div slot="footer" slot-scope="data" class="slot">
          <div class="float-right mb-5">
            <span v-if="data.rangeText != ' - '">
              {{ data.rangeText }}
            </span>
            <span class="m-4">
              <a
                @click="clearRange"
                v-if="!data.in_selection"
                class="btn btn-success btn-sm"
                >Clear</a
              >
            </span>
            <span>
              <a
                @click="data.clickCancel"
                v-if="!data.in_selection"
                class="btn btn-success btn-sm"
                >Cancel</a
              >
            </span>
            <span class="m-4">
              <a
                @click="data.clickApply"
                v-if="!data.in_selection"
                class="btn btn-success btn-sm"
                >Apply</a
              >
            </span>
          </div>
        </div>
      </date-range-picker>
    </div>
    <div>
      <span class="text-color"> <b>Scheduled Pay Date</b> </span> <br />
      <span>Select the pay date for this batch </span>
      <v-menu
        ref="menu"
        v-model="menu"
        class="py-3"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="ScheduleDate"
            class="pt-0"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker date v-model="form.date" no-title scrollable>
        </v-date-picker>
      </v-menu>
    </div>
    <div class="float-right mt-10">
      <span>
        <a @click="RESET" class="btn btn-danger btn-sm">Cancel</a>
      </span>
      <span class="m-4">
        <a @click="Apply" class="btn btn-success btn-sm"
          >Submit
          <span v-if="loader">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "processrollscreen1",
  components: { DateRangePicker },
  mixins: [validationMixin],
  validations: {
    form: {
      batchName: { required },
    },
  },
  data() {
    return {
      form: {
        batchName: null,
        date: null,
        // date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        //   .toISOString()
        //   .substr(0, 10),
        dates: {
          startDate: null,
          endDate: null,
        },
      },

      menu: false,

      loader: false,
    };
  },
  computed: {
    ...mapGetters(["TimePeriod", "Batch"]),
    computedDate() {
      return new Date();
    },
    computedDateMin() {
      return new Date();
    },
    formatDate2() {
      if (!this.form.date) return null;

      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
    ScheduleDate() {
      if (!this.form.date) return null;
      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
  },

  mounted() {
    // this.form.dates = {
    //   startDate: new Date().setDate(new Date().getDate() - 16),
    //   endDate: new Date().setDate(new Date().getDate() - 1),
    // };
  },
  methods: {
    clearRange() {
      this.form.dates = {
        startDate: null,
        endDate: null,
      };
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    async Apply() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        if (!this.Batch.some((x) => x.batchName == this.form.batchName)) {
          this.loader = true;
          var bn = this.form.batchName;
          var obj = this.Batch;
          var startDate;
          var endDate;
          if (this.form.dates.startDate) {
            startDate = this.dobformat2(this.form.dates.startDate);
          }
          if (this.form.dates.endDate) {
            endDate = this.dobformat2(this.form.dates.endDate);
          }
          if (this.form.batchName) {
            await this.$store
              .dispatch("getTimePeriod", {
                batchName: this.form.batchName,
                payDate: this.form.date,
                startdate: startDate,
                enddate: endDate,
              })
              .then((response) => {
                this.isfetching = false;
                if (response.status) {
                  this.$emit("screenOneData", this.form);
                  this.$bvModal.hide("filter");
                } else {
                  this.loader = false;
                  Swal.fire({
                    title: "",
                    text: response.message,
                    icon: "error",
                    confirmButtonClass: "btn btn-danger",
                  });
                }
              })
              .catch((ex) => {
                this.isfetching = false;
                
              });
          }
        } else {
          Swal.fire({
            title: "",
            text: "Batch name already exists",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          });
        }
      }
    },
    RESET() {
      (this.form.batchName = []),
        (this.form.date = []),
        (this.form.dates = {}),
        this.$bvModal.hide("filter");
    },
  },
};
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active {
  color: #2d79bd !important;
}
.v-btn--outlined {
  border: thin solid #2d79bd !important;
}
.text-color {
  color: #2d79bd;
}
.reportrange-text[data-v-1ebd09d2] {
  background: #fff;
  cursor: pointer;
  padding: 10px 10px;
  border: 1px solid #ccc;
  width: 100%;
  overflow: hidden;
  font-size: 15px;
}
.vue-daterange-picker[data-v-1ebd09d2] {
  display: block;
}
.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>
